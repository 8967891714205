<template>
    <div class="grid-cols-4 w-full max-h-[462px] h-[462px] absolute z-60 bg-white hidden md:grid" v-if="active"
         @mouseenter="menuShown(true)" @mouseleave="menuShown(false)">
        <div class="col-span-3 flex flex-col flex-wrap h-[462px] m-4">
            <div class="mb-4">
                <Link @click="menuShown(false)" :href="`/kategoriler/tum-urunler`"><h3 class="font-semibold">Tüm
                    Ürünler</h3></Link>
            </div>
            <template v-for="(menu, index) in menuItems" :key="index">
                <div class="mb-4 px-1">
                    <h3 class="font-semibold text-lg">
                        <Link v-if="menu.data.category_slug" @click="menuShown(false)" :href="`/kategoriler/${menu.data.category_slug}`">
                            {{ menu.label }}
                        </Link>
                        <a target="_blank" v-if="menu.data.url" @click="menuShown(false)" :href="`${menu.data.url}`">
                            {{ menu.label }}
                        </a>
                    </h3>
                    <ul class="mt-2 text-xs" v-for="(menuSubItem, subIndex) in menu.children" :key="subIndex">
                        <li class="text-gray-600">
                            <Link v-if="menuSubItem.data.category_slug" @click="menuShown(false)" :href="`/kategoriler/${menuSubItem.data.category_slug}`">
                                {{ menuSubItem.label }}
                            </Link>
                            <a target="_blank" v-if="menuSubItem.data.url" @click="menuShown(false)" :href="`${menuSubItem.data.url}`">
                                {{ menuSubItem.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <!--            <div>-->
            <!--                <Link href="https://kiralamini.com/" target="_blank">-->
            <!--                    <img class="w-full max-w-[150px]"-->
            <!--                         src="https://kiralamini.com/wp-content/uploads/2023/02/cropped-logo-2048x344.png" alt="">-->
            <!--                </Link>-->
            <!--            </div>-->
        </div>
        <div class="h-[462px] hidden ts:block">
            <Link href="/urun/logitech-g-g29-driving-force-yaris-direksiyonu">
                <img src="../../../images/menu-banner-new.png" alt="" class="object-contain" />
            </Link>
        </div>
    </div>
</template>

<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    name: "Menu",
    props: {
        active: {
            type: Boolean,
            default: true
        },
        menuItems: Object
    },
    emits: ["menuchange"],
    methods: {
        menuShown(val) {
            //console.log('close');
            this.$emit("menuchange", val);
        }
    },
    components: {
        Link
    }
};
</script>

<style scoped></style>
